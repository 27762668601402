var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "pa-0",
      on: {
        dragleave: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          _vm.isDrag = false
        },
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pa-2 text-center",
          attrs: { outlined: "" },
          on: {
            drop: function ($event) {
              $event.preventDefault()
              return _vm.dropFile.apply(null, arguments)
            },
            dragover: function ($event) {
              $event.preventDefault()
            },
            dragenter: function ($event) {
              _vm.isDrag = true
            },
          },
        },
        [
          _c(
            "v-container",
            { staticClass: "grey lighten-2" },
            [_vm._t("title")],
            2
          ),
          _c(
            "v-row",
            { staticClass: "text-center", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  attrs: { sm: "4" },
                },
                [
                  _c("Avatar", {
                    staticClass: "avatar",
                    attrs: { "av-size": "100", userId: _vm.userId },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  attrs: { sm: "8" },
                },
                [
                  _c("v-file-input", {
                    attrs: {
                      "hide-details": "auto",
                      outlined: "",
                      "show-size": "",
                      accept: "image/*",
                      label: "Profile Picture",
                      "prepend-inner-icon": "mdi-camera",
                      placeholder: "Select or Drag an image",
                      rules: _vm.fileRules,
                    },
                    model: {
                      value: _vm.file,
                      callback: function ($$v) {
                        _vm.file = $$v
                      },
                      expression: "file",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._t("tip"),
          _c("v-overlay", { attrs: { absolute: "", value: _vm.isDrag } }, [
            _c("h1", [_vm._v("Drop File")]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
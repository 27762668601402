var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$apollo.loading
    ? _c(
        "v-container",
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.validComp,
                callback: function ($$v) {
                  _vm.validComp = $$v
                },
                expression: "validComp",
              },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "py-2 mt-2",
                  attrs: { elevation: "0", "min-width": "250px" },
                },
                [
                  !_vm.isFeedPage && !_vm.isBillingPage
                    ? _c("ProfileAvatarEdit", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c("h3", { staticClass: "text-h6" }, [
                                    _vm._v("Set your profile picture"),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "tip",
                              fn: function () {
                                return [
                                  _c("h5", { staticClass: "text-body-2" }, [
                                    _vm._v(
                                      "Tip: Add your personal picture or your business' logo"
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4106928946
                        ),
                      })
                    : _vm._e(),
                  !_vm.isFeedPage && !_vm.isBillingPage
                    ? _c(
                        "v-card",
                        {
                          staticClass: "pa-2 mt-2 text-center",
                          attrs: { outlined: "" },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "grey lighten-2 mb-3" },
                            [
                              _c("h3", { staticClass: "text-h6" }, [
                                _vm._v("Personal Details"),
                              ]),
                            ]
                          ),
                          _vm.userComp
                            ? _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "align-center",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          rules: _vm.nameRules,
                                          counter: 30,
                                          label: "Personal Name",
                                        },
                                        model: {
                                          value: _vm.userComp.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.userComp, "name", $$v)
                                          },
                                          expression: "userComp.name",
                                        },
                                      }),
                                      _vm.userComp.private
                                        ? _c("v-text-field", {
                                            attrs: {
                                              outlined: "",
                                              label: "Email",
                                              disabled: "",
                                              "append-icon": "mdi-lock",
                                            },
                                            model: {
                                              value: _vm.userComp.private.email,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userComp.private,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "userComp.private.email",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "align-center",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          label: "Personal Description",
                                          hint: "Give a short description of your personal interests (optional)",
                                          "persistent-hint": "",
                                          counter: 500,
                                        },
                                        model: {
                                          value: _vm.userComp.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.userComp,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "userComp.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.userComp && _vm.userComp.private
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0" },
                            [
                              _c(
                                "v-sheet",
                                {
                                  staticClass: "pa-3",
                                  attrs: { color: "yellow lighten-2" },
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "text-left text-h6" },
                                    [_vm._v("Use KitchenWhiz for: (REQUIRED)")]
                                  ),
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticClass: "mt-0",
                                      attrs: {
                                        rules: _vm.typeRules,
                                        mandatory: false,
                                        dense: "",
                                      },
                                      model: {
                                        value: _vm.userComp.private.type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.userComp.private,
                                            "type",
                                            $$v
                                          )
                                        },
                                        expression: "userComp.private.type",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-radio",
                                        { attrs: { value: "homeowner" } },
                                        [
                                          _c(
                                            "template",
                                            { slot: "label" },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-h6 primary--text",
                                                    },
                                                    [_vm._v("Homeowner Use")]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-body-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Get design ideas and connect with Professional Cabinet Makers"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "v-radio",
                                        { attrs: { value: "professional" } },
                                        [
                                          _c(
                                            "template",
                                            { slot: "label" },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-h6 primary--text",
                                                    },
                                                    [_vm._v("Professional Use")]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-body-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Increase your design productivity and connect with Homeowners"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._t("business-edit"),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
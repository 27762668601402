var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            scrollable: "",
            persistent: "",
            "overlay-opacity": "0.95",
            "max-width": "700px",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [_c("ProfileEdit", { attrs: { user: _vm.user } })],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "flex-column action-buttons" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-2 white--text",
                      attrs: {
                        block: "",
                        "x-large": "",
                        color: "green",
                        type: "submit",
                        loading: _vm.loading,
                        disabled: !_vm.valid,
                      },
                      on: { click: _vm.editUser },
                    },
                    [_vm._v("Submit")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
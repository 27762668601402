var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.$route.path !== "/login"
        ? _c(
            "v-navigation-drawer",
            {
              attrs: { app: "", clipped: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c("v-footer", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-overline font-italic text-capitalize",
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    "x-small": "",
                                    href: "https://kitchenwhiz.com/privacy-policy/",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Privacy Policy")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    "x-small": "",
                                    href: "https://kitchenwhiz.com/terms-of-service/",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Terms of Use")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-caption font-italic text-capitalize",
                            },
                            [
                              _vm._v(
                                "Copyright © 2020 KitchenWhiz Inc. All Rights Reserved"
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2977043024
              ),
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _c(
                "v-list",
                { attrs: { nav: "", dense: "" } },
                [
                  _c(
                    "v-list-item-group",
                    { attrs: { "active-class": "" } },
                    [
                      _vm.isAuthenticated
                        ? _c(
                            "v-list-item",
                            { attrs: { to: "/my-designs" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-folder-multiple-image"),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("My Designs")]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAuthenticated
                        ? _c(
                            "v-list-item",
                            { attrs: { to: `/profile/${_vm.userId}` } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-account")])],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Profile")]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAuthenticated
                        ? _c(
                            "v-list-item",
                            { attrs: { to: `/billing` } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-credit-card")])],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Billing")]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAuthenticated
                        ? _c(
                            "v-list-item",
                            { attrs: { to: `/branded-editor` } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-web")])],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v("Add on website"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAuthenticated
                        ? _c(
                            "v-list-item",
                            { attrs: { to: `/costing-module` } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-currency-usd")])],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v("Costing Module"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "my-3" }),
              _c("ProjectDialogSidebar"),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-app-bar",
        { attrs: { app: "", "clipped-left": "", color: "primary", dark: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function ($event) {
                _vm.drawer = true
              },
            },
          }),
          _c("v-img", {
            staticClass: "shrink ml-0 mt-5 mr-2",
            style: { cursor: "pointer" },
            attrs: {
              alt: "KitchenWhiz icon",
              "max-height": "56px",
              contain: "",
              src: require("@/assets/wand.png"),
            },
            on: {
              click: function ($event) {
                return _vm.redirect("/feed")
              },
            },
          }),
          _c("v-img", {
            staticClass: "shrink mr-2",
            style: { cursor: "pointer" },
            attrs: {
              alt: "KitchenWhiz Logo",
              "max-height": "56px",
              contain: "",
              src: require("@/assets/kw-logo.png"),
            },
            on: {
              click: function ($event) {
                return _vm.redirect("/feed")
              },
            },
          }),
          _c("v-spacer"),
          _vm.isAuthenticated
            ? _c(
                "div",
                [
                  _c("Avatar", {
                    staticClass: "mr-3 d-none d-sm-inline",
                    attrs: { "av-size": "36", userId: _vm.userId },
                  }),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "d-none d-sm-inline",
                                        attrs: { right: "" },
                                        on: { click: _vm.logout },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v("mdi-logout")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2449379553
                      ),
                    },
                    [_c("span", [_vm._v("Logout")])]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
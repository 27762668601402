var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px", scrollable: "", fullscreen: "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on }) {
              return [_vm._t("default", null, { dialog: { on } })]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _vm.dialog
        ? _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitProject.apply(null, arguments)
                },
              },
              model: {
                value: _vm.validComp,
                callback: function ($$v) {
                  _vm.validComp = $$v
                },
                expression: "validComp",
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-2 close-btn",
                  attrs: { color: "blue darken-1", fab: "", "x-small": "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v("mdi-close"),
                  ]),
                ],
                1
              ),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pa-1 justify-center yellow accent-4" },
                    [
                      _c("v-col", { staticClass: "text-center pa-0" }, [
                        _c("h2", { staticClass: "text-h4 my-2" }, [
                          _vm._v(_vm._s(_vm.actionName)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mb-5",
                                          attrs: {
                                            align: "center",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              attrs: {
                                                row: "",
                                                "hide-details": "",
                                              },
                                              model: {
                                                value: _vm.project.isPublic,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.project,
                                                    "isPublic",
                                                    $$v
                                                  )
                                                },
                                                expression: "project.isPublic",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Private",
                                                  value: false,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Public",
                                                  value: true,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              label: "Title*",
                                              required: "",
                                              rules: _vm.titleRules,
                                              counter: _vm.charLimits.title.max,
                                              min: _vm.charLimits.title.min,
                                              max: _vm.charLimits.title.max,
                                            },
                                            model: {
                                              value: _vm.project.title,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.project,
                                                  "title",
                                                  $$v
                                                )
                                              },
                                              expression: "project.title",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              clearable: "",
                                              rows: "4",
                                              name: "description",
                                              label: "Descripe your project",
                                              placeholder:
                                                "Describe the kitchen shape, style or colors you need.",
                                              rules: _vm.descRules,
                                              counter:
                                                _vm.charLimits.description,
                                            },
                                            model: {
                                              value: _vm.project.description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.project,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression: "project.description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _vm.errors
                                        ? _c(
                                            "v-alert",
                                            {
                                              staticClass: "mt-3",
                                              attrs: { type: "error" },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.errors) + " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("p", [
                                    _c("small", [
                                      _vm._v("*indicates required field"),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "flex-column" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-2 white--text",
                          attrs: {
                            block: "",
                            "x-large": "",
                            color: "green",
                            type: "submit",
                            disabled: !_vm.validComp,
                          },
                        },
                        [_vm._v(_vm._s(_vm.actionName))]
                      ),
                      _c(
                        "p",
                        { staticClass: "mt-2 text-caption font-weight-light" },
                        [
                          _vm._v(" By moving forward you agree to our "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "pl-0",
                              attrs: {
                                text: "",
                                "x-small": "",
                                color: "blue lighten-2",
                                href: "https://kitchenwhiz.com/privacy-policy/",
                              },
                            },
                            [_vm._v("Privacy Policy")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
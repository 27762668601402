import { render, staticRenderFns } from "./ProfileEdit.vue?vue&type=template&id=55b9cbd3&scoped=true&"
import script from "./ProfileEdit.vue?vue&type=script&lang=js&"
export * from "./ProfileEdit.vue?vue&type=script&lang=js&"
import style0 from "./ProfileEdit.vue?vue&type=style&index=0&id=55b9cbd3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55b9cbd3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/render/project/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55b9cbd3')) {
      api.createRecord('55b9cbd3', component.options)
    } else {
      api.reload('55b9cbd3', component.options)
    }
    module.hot.accept("./ProfileEdit.vue?vue&type=template&id=55b9cbd3&scoped=true&", function () {
      api.rerender('55b9cbd3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ProfileEdit.vue"
export default component.exports
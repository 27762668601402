var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-avatar",
    {
      class: { avatar: _vm.avBorder },
      style: { cursor: `${_vm.avCursor}` },
      attrs: { tile: "", width: _vm.avSize, height: "auto" },
      on: {
        click: function ($event) {
          return _vm.redirect()
        },
      },
    },
    [
      _vm.$apollo.loading
        ? _c("v-skeleton-loader", {
            attrs: { loading: "", "max-width": "100", type: "avatar" },
          })
        : _vm.user.picture
        ? _c("img", { attrs: { alt: "Avatar", src: _vm.user.picture } })
        : _c("v-icon", { attrs: { "x-large": "" } }, [
            _vm._v("mdi-account-circle"),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
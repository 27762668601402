var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("AppBar"),
      _c(
        "v-main",
        [
          _vm.$apollo.loading
            ? _c(
                "v-container",
                { staticClass: "text-center" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" },
                  }),
                ],
                1
              )
            : _c(
                "v-container",
                { staticClass: "py-0", attrs: { fluid: "" } },
                [
                  !_vm.type && _vm.isAuthenticated
                    ? _c("ExtraUserInfo", { attrs: { user: _vm.user } })
                    : _vm._e(),
                  _vm.errors
                    ? _c(
                        "v-alert",
                        { staticClass: "mt-3", attrs: { type: "error" } },
                        [_vm._v(" " + _vm._s(_vm.errors) + " ")]
                      )
                    : _vm._e(),
                  _c("router-view"),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
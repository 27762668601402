var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.doesSessionExist
    ? _c(
        "v-container",
        { staticClass: "text-center" },
        [
          _c("ProjectDialog", {
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "white--text",
                            attrs: { color: "green" },
                          },
                          slotProps.dialog.on
                        ),
                        [
                          _vm._v(" Create Project "),
                          _c("v-icon", [_vm._v("mdi-plus")]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              2332605658
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }